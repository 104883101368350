$(document).ready(function () {
  initBooking();
  initTabLinks();
  initCustomSelect();
  initUpdatedBookingBar();
});

function initBooking() {
  $(".booking-bar__form, .booking-menu").each(function () {
    initDateSelection(this);
  });

  $(
    ".booking-bar__why-book button, .booking-bar-popup__inner .close-button, .booking-menu__why-book button"
  ).on("click", function () {
    $(".booking-menu__close").trigger("click");
    $(".booking-bar-popup").slideToggle();
  });

  $(".booking-bar__why-book button").on("click", function (e) {
    e.preventDefault(); // Stop this button from also submitting the form
  });

  if ($(".booking-bar__logo--right img").length > 0) {
    $(".booking-bar__wrapper").addClass("has-logo"); // Adds white border between booking bar and the logo, but only when the img element is there
  }
}

function formatDateForDateBlocks(date) {
  var CheckInDateNumber = date.getDate();
  var fullMonthOptions = { month: "long", year: "numeric" };
  var CheckInDateFullMonthAndYear = date.toLocaleDateString(
    "en-US",
    fullMonthOptions
  );

  return {
    CheckInDateNumber: CheckInDateNumber,
    CheckInDateFullMonthAndYear: CheckInDateFullMonthAndYear,
  };
}

function setBlockDates(checkInDate, checkOutDate) {
  var checkInNumber = checkInDate.CheckInDateNumber;
  var checkOutNumber = checkOutDate.CheckInDateNumber;

  if (checkInNumber < 10) {
    checkInNumber = "0" + checkInNumber;
  }

  if (checkOutNumber < 10) {
    checkOutNumber = "0" + checkOutNumber;
  }

  $("#check-in-block .number").html(checkInNumber);
  $("#check-in-block .text").html(checkInDate.CheckInDateFullMonthAndYear);

  $("#check-out-block .number").html(checkOutNumber);
  $("#check-out-block .text").html(checkOutDate.CheckInDateFullMonthAndYear);
}

function initDateSelection(bookingContainer) {
  var nowTemp = new Date();
  var today = new Date();
  var checkInField = $(bookingContainer).find(".check-in");
  var checkOutField = $(bookingContainer).find(".check-out");

  var availability = $(bookingContainer)
    .find('button[type="submit"]')
    .first()
    .data("availability");
  var openingDate =
    availability && !isNaN(Date.parse(availability))
      ? new Date(availability)
      : new Date();
  openingDate.setHours(12, 0, 0, 0);

  var tomorrow = new Date(openingDate);
  tomorrow.setDate(openingDate.getDate() + 1);
  tomorrow.setHours(12, 0, 0, 0);

  function formatDate(date, format) {
    var day = pad(date.getDate(), 2);
    var month = pad(date.getMonth() + 1, 2);
    var year = date.getFullYear();

    if (format === "mm/dd/yy") {
      return month + "/" + day + "/" + year;
    } else {
      return day + "/" + month + "/" + year;
    }
  }

  var dateFormat =
    $("body.theme-palm-house").length > 0 ? "mm/dd/yy" : "dd/mm/yy";
  $(checkInField).val(formatDate(openingDate, dateFormat));
  $(checkOutField).val(formatDate(tomorrow, dateFormat));

  var checkin = $(checkInField).datepicker({
    weekStart: 1,
    format: dateFormat,
    autoHide: true,
    date: openingDate,
    startDate: openingDate,
  });

  var checkout = $(checkOutField).datepicker({
    weekStart: 1,
    format: dateFormat,
    autoHide: true,
    date: tomorrow,
    startDate: tomorrow,
  });

  $(checkInField).on("hide.datepicker", function () {
    var checkInDate = $(checkInField).datepicker("getDate");
    var checkOutDate = $(checkOutField).datepicker("getDate");
    var dayAfterCheckIn = new Date(checkInDate);
    dayAfterCheckIn.setDate(checkInDate.getDate() + 1);
    dayAfterCheckIn.setHours(12, 0, 0, 0);

    if (checkInDate >= checkOutDate) {
      $(checkOutField).datepicker("setDate", dayAfterCheckIn);
      checkOutDate = dayAfterCheckIn;
    }

    $(checkInField).val(formatDate(checkInDate, dateFormat));
    $(checkOutField).val(formatDate(checkOutDate, dateFormat));

    setBlockDates(
      formatDateForDateBlocks(checkInDate),
      formatDateForDateBlocks(checkOutDate)
    );
  });

  $(checkOutField).on("hide.datepicker", function () {
    var checkInDate = $(checkInField).datepicker("getDate");
    var checkOutDate = $(checkOutField).datepicker("getDate");
    var dayBeforeCheckOut = new Date(checkOutDate);
    dayBeforeCheckOut.setDate(checkOutDate.getDate() - 1);
    dayBeforeCheckOut.setHours(12, 0, 0, 0);

    if (checkOutDate <= checkInDate) {
      $(checkInField).datepicker("setDate", dayBeforeCheckOut);
      checkInDate = dayBeforeCheckOut;
    }

    $(checkInField).val(formatDate(checkInDate, dateFormat));
    $(checkOutField).val(formatDate(checkOutDate, dateFormat));

    setBlockDates(
      formatDateForDateBlocks(checkInDate),
      formatDateForDateBlocks(checkOutDate)
    );
  });

  setBlockDates(
    formatDateForDateBlocks(openingDate),
    formatDateForDateBlocks(tomorrow)
  );

  $(bookingContainer).on("submit", function () {
    var checkInDate = $(checkInField).datepicker("getDate");
    var checkOutDate = $(checkOutField).datepicker("getDate");

    $(bookingContainer)
      .find('[name="arrive"]')
      .val(
        checkInDate.getFullYear() +
          "/" +
          pad(checkInDate.getMonth() + 1, 2) +
          "/" +
          pad(checkInDate.getDate(), 2)
      );
    $(bookingContainer)
      .find('[name="depart"]')
      .val(
        checkOutDate.getFullYear() +
          "/" +
          pad(checkOutDate.getMonth() + 1, 2) +
          "/" +
          pad(checkOutDate.getDate(), 2)
      );
  });
}

function initTabLinks() {
  $(".tabs-title a").on("click", function () {
    if ($(this).hasClass("link-not-tab")) {
      $("#" + $(this).attr("href").replace("#", ""))
        .find("a")[0]
        .click();
      return false;
    }
  });

  $('select[name="booking-select"]').on("change", function () {
    if ($(this).val() === "gift") {
      $('.booking-menu__tabs a[href="#gift"]')[0].click();
    } else if ($(this).val() === "class") {
      $('.booking-menu__tabs a[href="#class"]')[0].click();
    } else if ($(this).val().indexOf("table") > -1) {
      $('#table a[data-link="' + $(this).val() + '"]')[0].click();
    } else if ($(this).val().indexOf("spa") > -1) {
      $(".book-now").trigger("click");
      $("#spa-label").trigger("click");
    }
  });
}

function initCustomSelect() {
  var count = 0;
  $(".booking-bar__selector option").each(function () {
    if ($(this).attr("value") == "table-booking") {
      $(this).attr("value", $(this).attr("value") + "-" + count);
      count++;
    }
  });

  count = 0;
  $(".booking-menu__links a").each(function () {
    if ($(this).attr("data-link") == "table-booking") {
      $(this).attr("data-link", $(this).attr("data-link") + "-" + count);
      count++;
    }
  });

  $(".custom-select select").selectric();
}

function initUpdatedBookingBar() {
  var roomAccordion = $(".accordion.rooms");
  var guestAccordion = $(".accordion.guests");
  var roomSelectorBtn = $(".booking-rooms-summary");
  var guestSelectorBtn = $(".booking-bar-summary");

  var state = {
    isRoomActive: false,
    isGuestsActive: false,
  };

  var values = {
    rooms: 1,
    adults: 2,
    children: 0,
    maxRoom: 5,
    maxAdults: 8,
    maxChildren: 8,
    minAdults: 1,
    minRooms: 1,
  };

  var inputs = {
    rooms: $(".booking-bar-rooms"),
    adults: $(".booking-bar-adults"),
    children: $(".booking-bar-children"),
  };

  var labels = {
    rooms: $(".rooms-value"),
    totalRooms: $("#roomsTotalCount"),
    adults: $(".adults-value"),
    children: $(".children-value"),
    totalGuests: $("#guestsTotalCount"),
  };

  function setDefaultValue(number, input) {
    input.val(number);
  }

  function setAccordion(state, element) {
    if (state) {
      element.show();
    } else {
      element.hide();
    }
  }

  function handleAccordionChange(state, element1, element2) {
    if (state) {
      element1.show();
      element2.hide();
    } else {
      element1.hide();
    }
  }

  function handleClickOutSideOfTarget(e, accordion, selector, state) {
    if (
      !accordion.is(e.target) &&
      accordion.has(e.target).length === 0 &&
      !selector.is(e.target)
    ) {
      state = false;
      accordion.hide();
    }

    return;
  }

  function toggleGuestsAccordion(e) {
    state.isGuestsActive = !state.isGuestsActive;
    state.isRoomActive = false;
    handleAccordionChange(state.isGuestsActive, guestAccordion, roomAccordion);
    if (e.target.id === "booking-menu-summary") {
      $("#acc-bar-guests").css("opacity", 0);
    }
  }

  function toggleRoomAccordion(e) {
    state.isRoomActive = !state.isRoomActive;
    state.isGuestsActive = false;
    handleAccordionChange(state.isRoomActive, roomAccordion, guestAccordion);

    if (e.target.id === "booking-menu-rooms") {
      $("#acc-bar-rooms").css("opacity", 0);
    }
  }

  function appendNumOfGuests() {
    // var template = guestSelectorBtn.filter("[data-ilh-template]").data("ilhTemplate").replace("#", (values.adults + values.children));
    // guestSelectorBtn.val(template)
    labels.totalGuests.html(values.adults + values.children);
  }

  function appendNumOfRooms() {
    // var template = roomSelectorBtn.filter("[data-ilh-template]").data("ilhTemplate").replace("#", values.rooms);
    // roomSelectorBtn.val(template)
  }

  function setData(value, targetInput, targetLabels) {
    targetInput.val(value);

    targetLabels.forEach(function (targetLabel) {
      targetLabel.html(value);
    });
  }

  function incrementRooms() {
    if (values.rooms < values.maxRoom) {
      values.rooms++;
      values.totalRooms++;

      var hiddenInput = document.getElementById("roomsHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue + 1;
    }
    setData(values.rooms, inputs.rooms, [labels.rooms, labels.totalRooms]);
    appendNumOfRooms();
  }

  function decrementRooms() {
    if (values.rooms > values.minRooms) {
      values.rooms--;
      values.totalRooms--;

      var hiddenInput = document.getElementById("roomsHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue - 1;
    }
    setData(values.rooms, inputs.rooms, [labels.rooms, labels.totalRooms]);
    appendNumOfRooms();
  }

  function incrementAdults() {
    if (values.adults < values.maxAdults) {
      values.adults++;
      values.totalGuests++;

      var hiddenInput = document.getElementById("adultHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue + 1;
    }

    setData(values.adults, inputs.adults, [labels.adults, labels.totalGuests]);
    appendNumOfGuests();
  }

  function decrementAdults() {
    if (values.adults > values.minAdults) {
      values.adults--;
      values.totalGuests--;

      var hiddenInput = document.getElementById("adultHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue - 1;
    }
    setData(values.adults, inputs.adults, [labels.adults, labels.totalGuests]);
    appendNumOfGuests();
  }

  function incrementChildren() {
    if (values.children < values.maxChildren) {
      values.children++;
      values.totalGuests++;

      var hiddenInput = document.getElementById("childHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue + 1;
    }
    setData(values.children, inputs.children, [
      labels.children,
      labels.totalGuests,
    ]);
    appendNumOfGuests();
  }

  function decrementChildren() {
    if (values.children > 0) {
      values.children--;
      values.totalGuests--;

      var hiddenInput = document.getElementById("childHiddenInput");
      var currentValue = parseInt(hiddenInput.value) || 0;
      hiddenInput.value = currentValue - 1;
    }
    setData(values.children, inputs.children, [
      labels.children,
      labels.totalGuests,
    ]);
    appendNumOfGuests();
  }

  setDefaultValue(2, inputs.adults);
  setDefaultValue(0, inputs.children);
  setDefaultValue(1, inputs.rooms);
  setAccordion(state.isRoomActive, roomAccordion);
  setAccordion(state.isGuestsActive, guestAccordion);

  guestSelectorBtn.on("click", function (e) {
    toggleGuestsAccordion(e);
    appendNumOfGuests();
  });
  roomSelectorBtn.on("click", function (e) {
    toggleRoomAccordion(e);
  });
  $(".booking-bar").on("click", function (e) {
    handleClickOutSideOfTarget(
      e,
      roomAccordion,
      roomSelectorBtn,
      state.isRoomActive
    );
    handleClickOutSideOfTarget(
      e,
      guestAccordion,
      guestSelectorBtn,
      state.isGuestsActive
    );
  });
  $(".booking-menu").on("click", function (e) {
    handleClickOutSideOfTarget(
      e,
      roomAccordion,
      roomSelectorBtn,
      state.isRoomActive
    );
    handleClickOutSideOfTarget(
      e,
      guestAccordion,
      guestSelectorBtn,
      state.isGuestsActive
    );
  });
  $(".increment-rooms").on("click", incrementRooms);
  $(".decrement-rooms").on("click", decrementRooms);
  $(".increment-adults").on("click", incrementAdults);
  $(".decrement-adults").on("click", decrementAdults);
  $(".increment-children").on("click", incrementChildren);
  $(".decrement-children").on("click", decrementChildren);

  $(".booking-menu__close").on("click", function () {
    $("#acc-bar-rooms").css("opacity", 1);
    $("#acc-bar-guests").css("opacity", 1);
  });
}
